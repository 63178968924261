const $ = require('jquery');
import slick from 'slick-carousel';

$(function(){

'use strict';

/*******************************************
 * 
 * therapist single slider
 * 
 *******************************************/

(function() {

    // element
    var elm = $('.therapist-ProfileCard');

    // var
    
    // init
    function init() {
        setup();
    }

    //func
    function setup() {
        var elm_slider = elm.find('.therapist-ProfileCard__imageMain');
        var elm_thum = elm.find('.therapist-ProfileCard__imageThum');

        elm_slider.on('init', function(event, slick, currentSlide, nextSlide){
        });
        elm_slider.slick({
            fade : true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,

            dots: false,
            arrows: false,

            autoplay : false,
            speed: 300,

            pauseOnHover: false,
            pauseOnFocus: false,
            touchMove: true,
        });
        elm_slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
            elm_thum.find('.is-active').removeClass('is-active');
            elm_thum.find('._item').eq(nextSlide).addClass('is-active');
        });
        elm_thum.find('._item').eq(0).addClass('is-active');

        elm_thum.find('._item').each(function(index, element){
            $(this).on('click',function(){
                elm_slider.slick('slickGoTo',index);
            });
        });
    }

    init();

})();

/*******************************************
 *******************************************/
});