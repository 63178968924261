const $ = require('jquery');
import slick from 'slick-carousel';

$(function(){

'use strict';

/*******************************************
 * 
 * mv slider
 * 
 *******************************************/

(function() {

    // element
    var elm = $('.top-Mainvisual__slider');

    // var
    
    // init
    function init() {
        setup();
    }

    //func
    function setup() {
        elm.on('init', function(event, slick, currentSlide, nextSlide){
        });
        elm.slick({
            dots: false,
            arrows: false,
            autoplay : true,
            fade : true,
            speed: 600,
            pauseOnHover: false,
            pauseOnFocus: false,
            touchMove: false
        });
        elm.on('beforeChange', function(event, slick, currentSlide, nextSlide){
        });
    }

    init();

})();

/*******************************************
 *******************************************/
});