const $ = require('jquery');
import validate from 'jquery-validation';

$(function(){

'use strict';

/*******************************************
 * 
 * contact
 * 
 *******************************************/

(function() {
    
    // element
    var elm_form = $('#form_entry');

    // var
    
    // init
    function init() {
        if(elm_form.length){
            setup();
            test_setup();
        }
    }

    //func
    function setup() {
        elm_form.validate({
            rules : {
                form_name : { required: true },
                form_kana : { required: true },
                form_age : { required: true, number: true },
                form_area : { required: true },
                form_tel : { number: true },
                form_mail : { required: true, email: true },
                form_experience : { required: true },
            },
            ignore: ".is-disabled",
            // groups: {
            // 	xxx: "xxx xxx",
            // },
            errorElement : "error",
            errorClass: "error",
            messages: {
                form_name : {
                    required: "この項目は必須です。"
                },
                form_kana : {
                    required: "この項目は必須です。"
                },
                form_age : {
                    required: "この項目は必須です。",
                    number: "半角数字のみでご入力ください。",
                },
                form_area : {
                    required: "この項目は必須です。"
                },
                form_tel : {
                    required: "この項目は必須です。",
                    number: "半角数字のみでご入力ください。",
                },
                form_mail : {
                    required: "この項目は必須です。",
                    email: "メールアドレスが正しくありません。",
                },
                form_experience : {
                    required: "この項目は必須です。"
                },
            },
            errorPlacement: function(error, element){
                if( element.is(":radio") ){
                    error.appendTo( element.parent().parent().parent().parent() );
                }else if( element.is(":checkbox") ){
                    error.appendTo( element.parent().parent().parent().parent() );
                }else if( element.attr("name")=="xxx" ){
                    error.appendTo( element.parent() );
                }else{
                    // This is the default behavior 
                    error.appendTo( element.parent().parent().find('._error') );
                }
            }
        });
    }

    function test_setup(){
        $("#test_button").on('click', function () {
            $('[name=form_name]').val('名前名前');
            $('[name=form_kana]').val('ふりがなふりがな');
            $('[name=form_age]').val('22');
            $('[name=form_area]').val('兵庫県');
            $('[name=form_tel]').val('0123456789');
            $('[name=form_mail]').val('dummy@test.a.a');
            $('[name=form_experience]').eq(1).prop('checked', true);
            
        });
    }

    init();

})();

/*******************************************
 *******************************************/
});